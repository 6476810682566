<template>
    <h1>Yann SZYMANSKI</h1>

    <h2>Maintenance Mode</h2>

    <p>This site is temporarily under maintenance, thank you for your patience.</p>

    <p>Ce site est temporairement en maintenance, merci pour votre patience.</p>
</template>

<script>

</script>

<style>

</style>